import { toFieldPath } from "@stackbit/annotations";
import { motion } from "framer-motion";
import Image from "next/legacy/image";
import React from "react";
import { SharedSanitySectionProps } from "../../Layouts/types";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { Logo } from "./types";
import clsx from "clsx";
import { imageUrlFor } from "../../../lib/sanity/sanity.image";
interface LogosMarqueeProps extends SharedSanitySectionProps {
  logos?: Logo[];
}
const SectionLogosMarquee: React.FC<LogosMarqueeProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  logos = [],
  title
}) => {
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} {...toFieldPath(annotationPrefix)} className={clsx({
    "-mb-6 md:mb-0": title
  })} data-sentry-element="Section" data-sentry-component="SectionLogosMarquee" data-sentry-source-file="SectionLogosMarquee.tsx">
      <Container className="flex flex-col md:flex-row" data-sentry-element="Container" data-sentry-source-file="SectionLogosMarquee.tsx">
        <div className="z-10 flex flex-col  items-center md:items-center justify-center text-center md:text-left  md:w-[180px]">
          <h2 {...toFieldPath(".title")} className="text-plum-64">
            {title}
          </h2>
        </div>
        <div className="z-0 md:flex-1 overflow-hidden  w-full relative h-[120px] marquee-mask">
          <motion.div className="absolute left-0 top-0 flex flex-row items-center justify-start gap-x-3" animate={{
          x: ["0%", "-33.33%"]
        }} transition={{
          repeat: Infinity,
          repeatType: "loop",
          duration: 3 * (logos.length || 1),
          ease: "linear"
        }} data-sentry-element="unknown" data-sentry-source-file="SectionLogosMarquee.tsx">
            {logos.map((logo, index) => <MarqueeLogo logo={logo} key={index} {...toFieldPath(`.logos.[${index}]`)} />)}
            {logos.map((logo, index) => <MarqueeLogo logo={logo} aria-hidden="true" key={"A" + index} />)}
            {logos.map((logo, index) => <MarqueeLogo logo={logo} aria-hidden="true" key={"B" + index} />)}
          </motion.div>
        </div>
      </Container>
    </Section>;
};
function MarqueeLogo({
  logo,
  ...props
}: {
  logo: Logo;
}) {
  return <div className="h-[120px] w-[180px] " {...props} data-sentry-component="MarqueeLogo" data-sentry-source-file="SectionLogosMarquee.tsx">
      <Image className={clsx({
      "filter-to-plum opacity-40": logo.applyGrayscale !== false
    })} src={imageUrlFor(logo.image)} alt={logo.name} width={180} height={120} quality={100} data-sentry-element="Image" data-sentry-source-file="SectionLogosMarquee.tsx" />
    </div>;
}
export default SectionLogosMarquee;